import React, { useContext, useEffect, useState, useRef } from 'react'
import { Container } from '../Container'
import StyledPageContent from './style'
import { COLORS, SPACING } from '../../helpers/constants'
import { useTranslation } from '../../hooks/useTranslation'
import {
  AppContext,
  setCustomerProfile,
  updateCustomerProfile,
  getCustomerData,
} from '../../config/context'
import { Heading } from '../Heading'
import { Form } from '../Form'
import { Button } from '../Button'
import { Loader } from '../Svg'
import { isLoggedIn } from '../../services/auth'
import { Divider } from '../Divider'
import { formatErrors } from '../../helpers/texts'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby-link'
import { Text } from '../Text'
import { useResponsive } from '../../hooks/useResponsive'
import { Row } from '../Row'

const Profile = () => {
  if (!isLoggedIn()) {
    if (typeof window !== 'undefined') {
      navigate('/login')
    }
  }

  const getTranslation = useTranslation()
  const [state, dispatch] = useContext(AppContext)
  const { customer, orders, user } = state

  const [serverError, setServerError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)

  const [form, setForm] = useState({})
  const [success, setSuccess] = useState('')

  const responsive = useResponsive()

  const maxWidth = responsive?.windowSize?.width < 768 ? '100%' : '350px'
  const [mobileDevice, setMobileDevice] = useState(
    responsive?.windowSize?.width < 768
  )

  useEffect(() => {
    setMobileDevice(responsive?.windowSize?.width < 768)
  }, [responsive])

  const handleOnChange = (field, value) => {
    if (value && field !== 'email') {
      const data = { ...form }
      data[field] = value
      setForm(data)
    }
  }

  const { register, handleSubmit, errors } = useForm()

  const billingFields = [
    {
      type: 'text',
      label: getTranslation('firstnameText'),
      name: 'first_name',
      required: true,
      errorMessage: getTranslation('firstnameNeededText'),
      value: customer.first_name,
      valueChange: handleOnChange,
    },
    {
      type: 'text',
      label: getTranslation('lastnameText'),
      name: 'last_name',
      required: true,
      errorMessage: getTranslation('lastnameNeededText'),
      value: customer.last_name,
      valueChange: handleOnChange,
    },
    {
      type: 'text',
      label: getTranslation('adressOneText'),
      name: 'address_1',
      required: true,
      errorMessage: getTranslation('adressOneNeededText'),
      value: customer.address_1,
      valueChange: handleOnChange,
    },
    {
      type: 'text',
      label: getTranslation('cityText'),
      name: 'city',
      required: true,
      errorMessage: getTranslation('cityNeededText'),
      value: customer.city,
      valueChange: handleOnChange,
    },
    {
      type: 'text',
      label: getTranslation('postcodeText'),
      name: 'postcode',
      required: true,
      errorMessage: getTranslation('postcodeNeededText'),
      value: customer.postcode,
      valueChange: handleOnChange,
    },
    // {
    //     type: 'email',
    //     label: getTranslation('emailText'),
    //     name: 'email',
    //     required: true,
    //     errorMessage: getTranslation('emailNeededText'),
    //     value: customer.email,
    //     valueChange: handleOnChange
    // },
    {
      type: 'text',
      label: getTranslation('phoneText'),
      name: 'phone',
      required: true,
      errorMessage: getTranslation('phoneNeededText'),
      value: customer.phone,
      valueChange: handleOnChange,
    },
  ]

  useEffect(() => {
    if (orders.length) {
      var { billing, id } = orders[0]
      billing.id = user.user_id
      setForm(billing)
    }
    if (!customer || !customer.length) {
      dispatch(getCustomerData(user.user_id))
    }
  }, [])

  const updateProfile = () => {
    setLoading(true)
    dispatch(updateCustomerProfile(form)).then(res => {
      setLoading(false)
      if (res.errors) {
        setServerError(formatErrors(res))
      } else {
        dispatch(setCustomerProfile(res))
        setEditing(false)
        setSuccess(getTranslation('Updated!'))
      }
    })
  }

  const disabledCss = !editing
    ? {
        color: '#787878',
        cursor: 'default',
      }
    : {}

  return (
    <Container>
      <StyledPageContent>
        <Heading size={2} type={'h3'} color={COLORS.PRIMARY_RED}>
          {getTranslation('Profile')}
        </Heading>
        <Divider
          size={{
            desktop: SPACING.MEDIUM,
            tablet: SPACING.SMALL,
            mobile: SPACING.SMALL,
          }}
          line={COLORS.PRIMARY_DARK_BLUE}
        />
        <Row
          responsive={true}
          justify={mobileDevice ? 'inherit' : 'space-evenly'}
        >
          <Form maxWidth={maxWidth}>
            {billingFields.slice(0, 3).map((field, index) => {
              const {
                type,
                label,
                name,
                required,
                errorMessage,
                value,
                valueChange,
              } = field
              return (
                <Form.Group key={index}>
                  <Text>{label}</Text>
                  <Form.Input
                    style={disabledCss}
                    disabled={!editing}
                    type={type}
                    name={name}
                    defaultValue={value}
                    onChange={e => valueChange(name, e.target.value)}
                    innerRef={register({ required: required })}
                  />
                  {errors[name] && <Form.Error>{errorMessage}</Form.Error>}
                </Form.Group>
              )
            })}
            {serverError && <Form.Error>{serverError}</Form.Error>}
            <Divider
              size={{
                desktop: SPACING.MEDIUM,
                tablet: SPACING.SMALL,
                mobile: SPACING.SMALL,
              }}
            />
          </Form>
          <Form maxWidth={maxWidth}>
            {billingFields
              .slice(3, billingFields.length)
              .map((field, index) => {
                const {
                  type,
                  label,
                  name,
                  required,
                  errorMessage,
                  value,
                  valueChange,
                } = field
                return (
                  <Form.Group key={index}>
                    <Text>{label}</Text>
                    <Form.Input
                      style={disabledCss}
                      disabled={!editing}
                      type={type}
                      name={name}
                      defaultValue={value}
                      onChange={e => valueChange(name, e.target.value)}
                      innerRef={register({ required: required })}
                    />
                    {errors[name] && <Form.Error>{errorMessage}</Form.Error>}
                  </Form.Group>
                )
              })}
            {serverError && <Form.Error>{serverError}</Form.Error>}
            <Divider
              size={{
                desktop: SPACING.MEDIUM,
                tablet: SPACING.SMALL,
                mobile: SPACING.X_SMALL,
              }}
            />
          </Form>
        </Row>
        <Row responsive={true} justify={'center'}>
          {!editing ? (
            <Button
              size={'large'}
              color={COLORS.PRIMARY_RED}
              fullWidth={true}
              maxWidth={maxWidth}
              handleClick={() => {
                setEditing(true)
              }}
            >
              {getTranslation('Edit profile')}
              {loading && <Loader />}
            </Button>
          ) : (
            <Button
              size={'large'}
              color={COLORS.PRIMARY_DARK_BLUE}
              fullWidth={true}
              maxWidth={maxWidth}
              handleClick={handleSubmit(updateProfile)}
            >
              {getTranslation('Update')}
              {loading && <Loader />}
            </Button>
          )}
        </Row>
        <Divider
          size={{
            desktop: SPACING.MEDIUM,
            tablet: SPACING.SMALL,
            mobile: SPACING.X_SMALL,
          }}
        />
        <Row responsive={true} justify={'center'}>
          {success && <Text>{success}</Text>}
        </Row>
      </StyledPageContent>
      <Divider size={SPACING.XXX_LARGE} />
      <Divider size={SPACING.XXX_LARGE} />
    </Container>
  )
}

export { Profile }
